import React from "react";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function LoadingIndicator(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center',
        justifyContent: 'center' }}>
            {props.showPercentage ? <CircularProgress variant="determinate" {...props} style={{height: 100, width: 100}} /> : <CircularProgress style={{height: 100, width: 100}} />}
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary" style={{alignItems: 'center', textAlign: 'center'}}>
                    {props.showPercentage ? `${Math.round(props.value)}%` : ''}
                    {props.showText ? props.text : ''}
                </Typography>
            </Box>
        </Box>
    );
}

LoadingIndicator.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number,

    text: PropTypes.string,

    showText: PropTypes.bool,

    showPercentage: PropTypes.bool
  };