import React from "react";
import { Route, Switch } from 'react-router-dom';
import {useLogin} from "./auth/LoginProvider";
import LoadingIndicator from "./components/LoadingIndicator";
// import { LogInPage } from './pages/LogInPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { PrivateRoute } from './auth/PrivateRoute';
// import SignInSide from './pages/SignInSide';
import { UserInfoPage } from './pages/UserInfoPage';
import DashboardPage from "./pages/DashboardPage";

// const LogInPage = React.lazy(() =>
//     import(/* webpackChunkName: "LogInPage" */ "./pages/LogInPage")
// );

const SignInSide = React.lazy(() =>
    import(/* webpackChunkName: "SignInSide" */ "./pages/SignInSide")
);
// const UserInfoPage = React.lazy(() =>
//     import(/* webpackChunkName: "UserInfoPage" */ "./pages/UserInfoPage")
// );

const MailAdvertisingPage = React.lazy(() =>
    import(/* webpackChunkName: "MailAdvertisingPage" */ "./pages/MailAdvertisingPage")
);

const PartnerPage = React.lazy(() =>
    import(/* webpackChunkName: "PartnerPage" */ "./pages/PartnerPage")
);

const SettingsPage = React.lazy(() =>
    import(/* webpackChunkName: "SettingsPage" */ "./pages/SettingsPage")
);

const UserPage = React.lazy(() =>
    import(/* webpackChunkName: "UserPage" */ "./pages/UserPage")
);

export const Routes = () => {
    const {user} = useLogin();

    return (
        // <Router>
            <React.Suspense fallback={<LoadingIndicator />}>
                <Switch>
                    <PrivateRoute path="/" exact>
                        <DashboardPage />
                    </PrivateRoute>
                    <PrivateRoute path="/mail-advertising">
                        <MailAdvertisingPage partnerId={user ? user.partnerId : null} />
                    </PrivateRoute>
                    <PrivateRoute path="/partner">
                        <PartnerPage partnerId={user ? user.partnerId : null} />
                    </PrivateRoute>
                    <PrivateRoute path="/settings">
                        <SettingsPage />
                    </PrivateRoute>
                    <PrivateRoute path="/user-info" exact>
                        <UserInfoPage />
                    </PrivateRoute>
                    <PrivateRoute path="/user" exact>
                        <UserPage userId={user ? user.id : null} />
                    </PrivateRoute>
                    <Route path="/login">
                        <SignInSide />
                    </Route>
                    <Route path="/forgot-password/:token">
                        <SignInSide />
                    </Route>
                    <Route path="/forgot-password">
                        <SignInSide />
                    </Route>
                    <Route>
                        <NotFoundPage />
                    </Route>
                </Switch>
            </React.Suspense>
        // </Router>
    );
}