import * as React from 'react';
import {Collapse, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import List from "@mui/material/List";
// import ListSubheader from "@mui/material/ListSubheader";
import { Link as RouterLink } from 'react-router-dom';

export default function NestedSettingsList() {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="settings-list-subheader"
            // subheader={
            //     <ListSubheader component="div" id="settings-list-subheader">
            //         Settings
            //     </ListSubheader>
            // }
        >
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} component={RouterLink} to="/user-info">
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="User settings" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary="Partner settings" />
                    </ListItemButton>
                </List>
            </Collapse>
        </List>
    );
}