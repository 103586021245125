import { Routes } from './Routes';
import {useLogin} from "./auth/LoginProvider";
import {Container, ThemeProvider, Toolbar} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Header from './components/Header';
import Copyright from "./components/Copyright";
import './App.css';

const App = () => {
    const { loggedIn } = useLogin();

    const mdTheme = createTheme();

    return (
        // <div className="page-container">
        //     <Routes />
        // </div>
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                { loggedIn ? <Header theme={mdTheme} /> : null }
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    { loggedIn ? <Toolbar /> : null }
                    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                        <Routes />
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default App;