import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { Link as RouterLink } from 'react-router-dom';
import {Grid} from "@mui/material";
import Gravatar from "../Gravatar";

export default function WelcomeTile({user}) {
    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{ flex: 1 }}>
                <Grid item xs={9}>
                    <Title>Hallo</Title>
                    <Typography component="p" variant="h4">
                        {user.firstName}!
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                        Herzlich Willkommen
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Gravatar
                        email={user.email}
                        size={75}
                        sx={{ width: 75, height: 75 }}
                    />
                </Grid>
            </Grid>
            <div>
                <Link color="primary" component={RouterLink} to="/user">
                    Profil anzeigen
                </Link>
            </div>
        </React.Fragment>
    );
}