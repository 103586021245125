import React from "react";
import { useSnackbar } from 'notistack';

const LoginContext = React.createContext(undefined);

export default function LoginProvider({ children }) {
    // eslint-disable-next-line
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [token, setTokenInternal] = React.useState(() => {
        return localStorage.getItem('token');
    });
    const [loggedIn, setLoggedIn] = React.useState(() => {
        return token ? true : false;
    });
    const [user, setUserInternal] = React.useState(() => {
        return JSON.parse(localStorage.getItem('user'));
    });

    const setToken = newToken => {
        localStorage.setItem('token', newToken);
        setTokenInternal(newToken);
        setLoggedIn(true);
    }

    const setUser = newUser => {
        localStorage.setItem('user', JSON.stringify(newUser));
        setUserInternal(newUser);
    }

    function login(token, user) {
        setUser(user);
        setToken(token);
    }

    function logout() {
        localStorage.removeItem('token');
        setTokenInternal(undefined);

        localStorage.removeItem('user');
        setUserInternal(undefined);

        setLoggedIn(false);

        enqueueSnackbar('Logout successful!', {variant: "success"});
    }

    return (
        <LoginContext.Provider
            value={{
                loggedIn,
                token,
                user,
                login,
                logout
            }}
        >
            {children}
        </LoginContext.Provider>
    );
}

export function useLogin() {
    return React.useContext(LoginContext);
}