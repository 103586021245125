import "react-app-polyfill/ie11";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import {BrowserRouter as Router} from "react-router-dom";
import { SnackbarProvider } from 'notistack';

import App from './App';
import LoginProvider from "./auth/LoginProvider";

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <SnackbarProvider maxSnack={3}>
                <LoginProvider>
                    <App />
                </LoginProvider>
            </SnackbarProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
