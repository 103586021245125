import * as React from 'react';
import WelcomeTile from "../components/dashboard/WelcomeTile";
import {Grid, Paper} from "@mui/material";
import { useLogin } from "../auth/LoginProvider";

export default function DashboardPage() {
    const {user} = useLogin();

    return (
        <Grid item xs={12} md={4} lg={3}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                }}
            >
                <WelcomeTile user={user} />
            </Paper>
        </Grid>
    );
}